import React from 'react';
import { NextPage } from 'next';
import { withProtectRoute } from '@typevid/ui-shared';
import { Dashboard } from '@typevid/ui-pages';

export const TeamPage: NextPage = () => {
  return <Dashboard />;
};

export default withProtectRoute(TeamPage);
